function sendHeartbeat(status) {
    fetch('/heartbeat', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({
            status: status,
        })
    })
}
// Send a heartbeat every 30 seconds
setInterval(sendHeartbeat('online'), 5000);


window.addEventListener('beforeunload', function (event) {
    sendHeartbeat('offline')
});


