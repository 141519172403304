import './bootstrap';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, deleteToken } from "firebase/messaging";
import './notifications';
import './hearbeat';
import './chat';
import { lenisMain, mobile } from './lenis';
import.meta.glob(['../images/**',]);

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then(function (registration) {
            //console.log('Service Worker registered with scope:', registration.scope);
        }).catch(function (error) {
            //console.log('Service Worker registration failed:', error);
        });
}

function handlePermissionChange() {
    if (Notification.permission === 'denied' || Notification.permission === 'default') {
        const token = localStorage.getItem('fcm_token');
        if (token) {
            unsubscribeToken(token);
            localStorage.removeItem('fcm_token');
        }
    }
}

function registerToken(token) {
    fetch('/register-token', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': csrfToken,
        },
        body: JSON.stringify({ token: token })
    })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
}

// Function to delete FCM token
function unsubscribeToken(token) {
    deleteToken(messaging, token).then(() => {
        //console.log('Token deleted successfully.');
        fetch('/delete-token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
            },
            body: JSON.stringify({ token: token })
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.error('Error:', error));
    }).catch((err) => {
        //console.error('Error deleting token:', err);
    });
}

const firebaseConfig = {
    apiKey: "AIzaSyCHWGNj5D6GUiQGm2hZlkgovmWy2Ut2zJk",
    authDomain: "standapp-2024.firebaseapp.com",
    projectId: "standapp-2024",
    storageBucket: "standapp-2024.appspot.com",
    messagingSenderId: "102449983528",
    appId: "1:102449983528:web:c18be964fdfad0e14a051d",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
//console.log(messaging)

const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

let tokenTest;

$('.testBtn').on('click', function () {
    registerToken(tokenTest);
})

// Request permission and register token
Notification.requestPermission()
    .then((permission) => {
        if (permission === 'granted') {
            return getToken(messaging, { vapidKey: 'BN8GvRltwhkJPD8qaDJAh6vgDZHolxAuB_SMpbRplWtQ0N9uAdUGZw2uJ6zDR-uN3454AM-x6CAyryqAMa4Qy-o' }); // Replace with your actual VAPID public key
        } else {
            //console.error('Permission not granted for Notification');
        }
    })
    .then((token) => {
        if (token) {
            //console.log('FCM Token:', token);
            tokenTest = token;
            registerToken(token);
            localStorage.setItem('fcm_token', token);
        }
    })
    .catch((err) => {
        //console.error('catch', err);
    });

onMessage(messaging, (payload) => {
    //console.log('Message received. ', payload);
    // Customize notification here
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.icon,
    };

    if (Notification.permission === 'granted') {
        new Notification(notificationTitle, notificationOptions);
    }
});

setInterval(() => {
    handlePermissionChange();
}, 5000);


let currentChannelId = null;
Livewire.on('change-chat-channel', (event) => {

    if (currentChannelId) {
        Echo.leave('conversation.' + currentChannelId);
        Echo.leave('conversation.' + currentChannelId + '.readMessages');
    }

    currentChannelId = event.conversationId;

    // listen for new messages
    Echo.private('conversation.' + event.conversationId).listen('Chat', (event) => {
        Livewire.dispatch('listenForMessage', { id: event.message.id, content: event.message.content });
    })

    // listen for read messages status change
    Echo.private('conversation.' + event.conversationId + '.readMessages').listen('ChatMessageStatus', (event) => {
        Livewire.dispatch('setReadStatus', { readMessagesIds: event.readMessagesIds });
        //console.log(event.readMessagesIds)
    })
});

Livewire.on('leave-chat-channel', (event) => {
    if (currentChannelId) {
        Echo.leave('conversation.' + currentChannelId);
        Echo.leave('conversation.' + currentChannelId + '.readMessages');
    }
});

// joining presence channels
Livewire.on('join-presence-channels', (event) => {

    event.channelIds.forEach(channelId => {
        window.Echo.join(`team-users.${channelId}`)
            .here(users => {
                //console.log(`Users currently in room ${channelId}:`, users);
            })
            .joining(user => {
                Livewire.dispatch('member-presence-changed', { id: user.id, action: 'join' });
                //console.log(`User joined room ${channelId}:`, user);
            })
            .leaving(user => {
                Livewire.dispatch('member-presence-changed', { id: user.id, action: 'leave' });
                //console.log(`User left room ${channelId}:`, user);
            });
    });
})

document.addEventListener('livewire:load', () => {
    document.addEventListener('livewire-upload-progress', function (event) {
        //console.log(event);
    });
});






