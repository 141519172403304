$('.chat-userlist-button').on('click', function () {
    $('.chat-userlist-button').removeClass('active')
    $(this).addClass('active')
});


document.addEventListener('DOMContentLoaded', function () {
    const dragDropOverlay = document.getElementById('drag-drop-container');
    let dragCounter = 0;
    document.addEventListener('dragenter', function (e) {
        e.stopPropagation();
        e.preventDefault();
        dragCounter++;
        $('#drag-drop-container').addClass('activate')
    });

    document.addEventListener('dragleave', function (e) {
        e.stopPropagation();
        e.preventDefault();
        dragCounter--;
        if (dragCounter <= 0) {
            $('#drag-drop-container').removeClass('activate')
        }
    });

    document.addEventListener('dragover', function (e) {
        e.stopPropagation();
        e.preventDefault();
    });

    document.addEventListener('drop', function (e) {
        e.stopPropagation();
        e.preventDefault();
        dragCounter = 0;
        $('#drag-drop-container').removeClass('activate')
    });
});