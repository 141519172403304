import Lenis from '@studio-freight/lenis'

// detect mobile
let mobile = false
if (window.innerWidth < 1024) {
    mobile = true
}

let lenisMain;
let lenisChat;
const chatWindow = document.getElementById('messages');
if (!mobile) {
    let lenisProps = {
        duration: 1,
        easing: (x) => (1 - Math.pow(1 - x, 4))
    }

    lenisMain = new Lenis({
        duration: lenisProps.duration,
        easing: lenisProps.easing,
        direction: "vertical",
        gestureDirection: "vertical",
        smooth: true,
        smoothTouch: true,
        touchMultiplier: 2,
        autoResize: true,
        onScroll: (e) => {
            // Example: Allow scrolling inside .scrollable-div
            if (chatWindow && chatWindow.contains(e.target)) {
                return;
            }
            // Default Lenis behavior
            lenis.raf(e);
        }
    });


    // lenisChat = new Lenis({
    //     wrapper: chatWindow,
    //     duration: 1,
    //     easing: lenisProps.easing,
    //     direction: "vertical",
    //     gestureDirection: "vertical",
    //     smooth: true,
    //     smoothTouch: true,
    //     touchMultiplier: 2,
    //     autoResize: true
    // });

    function raf(time) {
        lenisMain.raf(time)
        //lenisChat.raf(time)
        requestAnimationFrame(raf)
    }
    requestAnimationFrame(raf)

    // lenis scroll to anchor global function
    $('.scrollToAnchor').on('click', function (e) {
        e.preventDefault();
        lenisMain.scrollTo('#' + this.dataset.target, {
            offset: window.innerWidth < 1200 ? (parseInt(this.dataset.offset)) / 2.5 : parseInt(this.dataset.offset),
            duration: 2,
            easing: (x) => (x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2)
        });
    })

    chatWindow.addEventListener('wheel', (event) => {
        event.stopPropagation(); // Prevent Lenis from handling scroll events inside the scrollable div
    });

    chatWindow.addEventListener('touchmove', (event) => {
        event.stopPropagation(); // Prevent Lenis from handling touch events inside the scrollable div
    });
}

// // Scroll to the bottom of the scrollable div once content is fully loaded
// function scrollToBottom() {
//     lenisChat.scrollTo(chatWindow.scrollHeight, { duration: 1.2, easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)) });
// }

// window.addEventListener('load', scrollToBottom);

// // Ensure scrolling to the bottom if images load after window load
// const images = chatWindow.querySelectorAll('img, video');
// let imagesLoaded = 0;

// images.forEach(image => {
//     image.addEventListener('load', () => {
//         imagesLoaded++;
//         if (imagesLoaded === images.length) {
//             scrollToBottom();
//         }
//     });

//     // If the image is already loaded (from cache), increment the counter
//     if (image.complete) {
//         imagesLoaded++;
//         if (imagesLoaded === images.length) {
//             scrollToBottom();
//         }
//     }
// });

export { lenisMain, mobile }

//lenis.stop()